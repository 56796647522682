import React from 'react';

/*
 * Accepts a function that handles closing a component whenever anywhere in the backdrop is clicked.
 * It returns a ref to be passed to the component/modal.
 * This can be used to control hiding modals/popups or dialogues.
 */
export const useOutsideClick = (
  closeComponent: () => void,
): React.RefObject<HTMLDivElement> => {
  const componentToCloseRef = React.useRef<HTMLDivElement>(null);

  const handleClose = (e: MouseEvent) => {
    const componentToClose = componentToCloseRef.current;
    if (!componentToClose || !componentToClose?.contains(e.target as Element)) {
      closeComponent();
    }
  };

  React.useEffect(() => {
    window.addEventListener('mousedown', handleClose);
    return () => window.removeEventListener('mousedown', handleClose);
  }, []);

  return componentToCloseRef;
};

/* 
 Headless UI Dialog component has issues while switching between pages due to SSR. 
 If two pages uses Dialog component, it sometimes closes thing when switching between pages.
 This hook is used to fix that. Taken from github issue page.
 https://github.com/tailwindlabs/headlessui/issues/479
 TODO: In future, replace FullScreenModal component with different Dialog
*/
export default function useIsMounted() {
  const [isMounted, setIsMounted] = React.useState(false);
  React.useEffect(() => {
    setIsMounted(true);
  }, []);
  return isMounted;
}

export function useMedia(mediaQuery: string) {
  const match = () => {
    if (!window.matchMedia) {
      return false;
    }
    return window.matchMedia(mediaQuery).matches;
  };

  const [value, set] = React.useState(match);

  React.useEffect(() => {
    // Update state on window `resize` event.
    // Usage of `match` function defined outside of `useEffect`
    // ensures that it has current values of arguments.
    const handler = () => set(match);
    window.addEventListener('resize', handler);
    // Remove event listener on cleanup.
    return () => window.removeEventListener('resize', handler);
  });

  return value;
}
