import clientLogger from "client/services/client-logger";
var errorCodesMap = {
    "auth/email-already-in-use": "Email already in use",
    "auth/invalid-email": "Invalid email",
    "auth/user-not-found": "User not found",
    "auth/wrong-password": "Incorrect password",
    "auth/weak-password": "Weak password",
    "auth/too-many-requests": "Too many requests",
    "auth/timeout": "Network timeout",
    "auth/user-token-expired": "Token expired",
    "auth/popup-blocked": "Popup blocked",
    "auth/popup-closed-by-user": "Popup closed",
    "auth/quota-exceeded": "Quota exceeded",
    "auth/network-request-failed": "Network failed",
    "auth/null-user": "Null user",
    "auth/invalid-action-code": "Invalid code",
    "auth/invalid-verification-code": "Invalid code",
    "auth/code-expired": "Code expired",
    "auth/admin-restricted-operation": "Restricted"
};
export var firebaseErrorCodesLookup = function(code) {
    if (!errorCodesMap[code]) {
        clientLogger.error("Missing firebase error code", {
            code: code
        });
        return "Something went wrong";
    }
    return errorCodesMap[code];
};
