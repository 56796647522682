export var AuthErrorCodes;
(function(AuthErrorCodes) {
    AuthErrorCodes["EMAIL_ALREADY_IN_USE"] = "auth/email-already-in-use";
    AuthErrorCodes["INVALID_EMAIL"] = "auth/invalid-email";
    AuthErrorCodes["USER_NOT_FOUND"] = "auth/user-not-found";
    AuthErrorCodes["INVALID_PASSWORD"] = "auth/wrong-password";
    AuthErrorCodes["WEAK_PASSWORD"] = "auth/weak-password";
    AuthErrorCodes["TOO_MANY_ATTEMPTS_TRY_LATER"] = "auth/too-many-requests";
    AuthErrorCodes["TIMEOUT"] = "auth/timeout";
    AuthErrorCodes["TOKEN_EXPIRED"] = "auth/user-token-expired";
    AuthErrorCodes["POPUP_BLOCKED"] = "auth/popup-blocked";
    AuthErrorCodes["POPUP_CLOSED_BY_USER"] = "auth/popup-closed-by-user";
    AuthErrorCodes["QUOTA_EXCEEDED"] = "auth/quota-exceeded";
    AuthErrorCodes["NETWORK_REQUEST_FAILED"] = "auth/network-request-failed";
    AuthErrorCodes["NULL_USER"] = "auth/null-user";
    AuthErrorCodes["INVALID_OOB_CODE"] = "auth/invalid-action-code";
    AuthErrorCodes["INVALID_CODE"] = "auth/invalid-verification-code";
    AuthErrorCodes["CODE_EXPIRED"] = "auth/code-expired";
    AuthErrorCodes["ADMIN_ONLY_OPERATION"] = "auth/admin-restricted-operation";
})(AuthErrorCodes || (AuthErrorCodes = {}));
