function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
import regeneratorRuntime from "/vercel/path0/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { sendEmailVerification } from "firebase/auth";
export var sendEmailVerificationLink = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(user) {
        var actionCodeSettings;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    actionCodeSettings = {
                        handleCodeInApp: true,
                        url: "".concat(window.location.origin, "/auth-action")
                    };
                    _ctx.next = 3;
                    return sendEmailVerification(user, actionCodeSettings);
                case 3:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function sendEmailVerificationLink(user) {
        return _ref.apply(this, arguments);
    };
}();
